import React, { useState, useRef } from 'react';

const MealForm = ({ onSubmit, onClick, isListShowing }) => {
  const [meal, setMeal] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  console.log('mealform')
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = sendAudioToWhisper;

      audioChunksRef.current = [];
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const sendAudioToWhisper = async () => {
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.webm');
    formData.append('model', 'whisper-1');

    try {
      const response = await fetch('/api/transcribe', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Transcription failed');
      }

      const data = await response.json();
      setMeal(prevMeal => (prevMeal + ' ' + data.text).trim());
    } catch (error) {
      console.error('Error transcribing audio:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(meal);
    setMeal('');
  };
  console.log({isListShowing})
  return (
    <form id="meal-form" onSubmit={handleSubmit}>
      <ListIcon onClick={onClick} isListShowing={isListShowing} />
      <div
        style={{
          display: 'flex',
        }}
      >
      <input
        type="text"
        id="meal-input"
        value={meal}
        onChange={(e) => setMeal(e.target.value)}
        placeholder="Enter your meal"
        required
      />
      {/* <button type="button" onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? 'Stop' : 'Start'} Recording
      </button> */}
      <button type="submit">Add</button>
      </div>
    </form>
  );
};

export const ListIcon = ({ onClick, isListShowing }) => (
  
  <div className="list-icon" onClick={onClick}>
    {isListShowing ? (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="12" y1="5" x2="12" y2="19"></line>
        <line x1="5" y1="12" x2="19" y2="12"></line>
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="8" y1="6" x2="21" y2="6"></line>
        <line x1="8" y1="12" x2="21" y2="12"></line>
        <line x1="8" y1="18" x2="21" y2="18"></line>
        <line x1="3" y1="6" x2="3.01" y2="6"></line>
        <line x1="3" y1="12" x2="3.01" y2="12"></line>
        <line x1="3" y1="18" x2="3.01" y2="18"></line>
      </svg>
    )}
  </div>
);


export default MealForm;