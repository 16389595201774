import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { NutritionSummary } from './components/NutritionSummary';
import { ListIcon } from './components/ListIcon';
import ItemizedList from './components/ItemizedList';
import SimpleChart from './components/SimpleChart';
import MealForm from './components/MealForm';
import { DateSelector } from './components/DateSelector';
import { useNutritionData } from './hooks/useNutritionData';
import { useDate } from './hooks/useDate';
import './index.css';

function App() {
  const { formattedDate, selectedDate, incrementDate, decrementDate, previousDate, nextDate } = useDate();
  const {
    meals,
    totalNutrition,
    handleMealSubmit,
    handleDeleteFoodItem,
    lastWeeksData,
  } = useNutritionData(selectedDate);

  const [selectedMacro, setSelectedMacro] = useState(null);
  const [showItemizedList, setShowItemizedList] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => incrementDate(),
    onSwipedRight: () => decrementDate(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const handleMacroClick = (macro) => {
    setSelectedMacro(macro);
    setShowItemizedList(false);
  };

  const handleNutritionClick = () => {
    setShowItemizedList(prev => !prev);
    setSelectedMacro(null);
  };

  return (
    <div className="App" {...handlers}>
      <DateSelector
        formattedDate={formattedDate}
        incrementDate={incrementDate}
        decrementDate={decrementDate}
        previousDate={previousDate}
        nextDate={nextDate}
      />
      <div className="nutrition-container">
        <NutritionSummary
          totalNutrition={totalNutrition}
          onItemClick={handleMacroClick}
        />
      </div>
      {showItemizedList ? (
        <div className="itemized-list-container">
          <ItemizedList
            meals={meals}
            onDelete={handleDeleteFoodItem}
          />
          <MealForm onSubmit={handleMealSubmit} onClick={handleNutritionClick} isListShowing={showItemizedList} />
        </div>
        
      ) : selectedMacro ? (
        <div
          style={{
            height: '100%',
          }}
         >
          <SimpleChart
            data={lastWeeksData.map(day => ({
              date: day.date,
              value: day.totalNutrition[selectedMacro]
            }))}
            macro={selectedMacro}
          />
        <MealForm onSubmit={handleMealSubmit} onClick={handleNutritionClick} isListShowing={showItemizedList} />
        </div>
      ) : (
        <MealForm onSubmit={handleMealSubmit} onClick={handleNutritionClick} isListShowing={showItemizedList} />
      )}

    </div>
  );
}

export default App;
